import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export class FirebaseTranslateLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    private firebaseUrl: string
  ) {}

  getTranslation(lang: string): Observable<any> {
    const url = `${this.firebaseUrl}${lang}.json?alt=media`; // Make sure this URL points directly to the file content
    return this.http.get<any>(url);
  }
}
