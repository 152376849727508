import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export class FallbackTranslateLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    private fallbackPath: string
  ) {}

  getTranslation(lang: string): Observable<any> {
    return this.http.get(`${this.fallbackPath}/${lang}.json`);
  }
}
