<div class="main">
  <div class="below-header">
    <div class="content-wrapper">
      <div class="main-content">
        <router-outlet></router-outlet>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
