import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { distinctUntilChanged, forkJoin, map, Observable, shareReplay, switchMap } from 'rxjs';
import { GalleryDTO, GalleryObject } from '../models/Gallery';
import { environment } from '../../../../environments/environment';
import { Doing } from '../../../shared/models/doingDTO';
import { DoingsService } from '../../../admin/services/doings.service';
import { GoogleService } from '../../../shared/services/google.service';
import { GetGalleryData, LoadAllForId } from '../store/gallery.action';
import { Store } from '@ngxs/store';

/**
 * This is only used when environment.features.imagesViaFirebaseStorage is used. Otherwise the images from drive- so
 * googleService will be used.
 */
@Injectable({ providedIn: 'root' })
export class GalleryService {
  constructor(
    private firestore: AngularFirestore,
    private googleService: GoogleService,
    private doingsService: DoingsService,
    private store: Store
  ) {}

  firestoreDatesCollection = this.firestore.collection('gallery');

  loadGalleryData() {
    this.store.dispatch(new GetGalleryData());
  }

  loadAllForGalleryEntry(id: string) {
    this.store.dispatch(new LoadAllForId(id));
  }

  // READ
  private driveGalleryData$: Observable<GalleryObject[]>;
  private fireStorageGalleryData$: Observable<GalleryObject[]> =
    this.firestoreDatesCollection.snapshotChanges().pipe(
      distinctUntilChanged(),
      map((gallery) =>
        gallery
          .map((gallery) => {
            const mappedGallery = gallery.payload.doc.data() as GalleryDTO;
            mappedGallery.id = gallery.payload.doc.id;
            mappedGallery.date = mappedGallery.date?.toDate();
            return mappedGallery;
          })
          .sort((a, b) => b.date.getTime() - a.date.getTime())
      ),
      shareReplay(1)
    );

  get galleryData() {
    return environment.features?.imagesViaFirebaseStorage
      ? this.fireStorageGalleryData$
      : this.driveGalleryData;
  }

  get driveGalleryData() {
    if (!this.driveGalleryData$) {
      this.driveGalleryData$ = this.doingsService.doingsWithDriveFolders.pipe(
        switchMap((doings: any) =>
          forkJoin(
            <Observable<GalleryObject>[]>doings
              .sort(
                (
                  a: { date: string | number | Date },
                  b: { date: string | number | Date }
                ) => new Date(b.date).getTime() - new Date(a.date).getTime()
              )
              .map((doing: Doing) =>
                this.googleService
                  .getFilesFromDriveFolderId(doing.driveFolder?.id || '')
                  .pipe(
                    map(
                      (value) =>
                        ({
                          title: doing.title,
                          date: doing.date,
                          doingId: doing.id,
                          driveFolder: doing.driveFolder?.id,
                          imageURLs: value.files
                            .filter(
                              (file: any) =>
                                file.mimeType && file.mimeType.includes('image')
                            )
                            .map(
                              (file: any) =>
                                `https://drive.google.com/uc?export=view&id=${file.id}`
                            ),
                          videoURLs: value.files
                            .filter(
                              (file: any) =>
                                file.mimeType && file.mimeType.includes('video')
                            )
                            .map(
                              (file: any) =>
                                `https://drive.google.com/uc?export=view&id=${file.id}`
                            ),
                        }) as GalleryObject
                    )
                  )
              )
          )
        ),
        shareReplay(1)
      );
    }
    return this.driveGalleryData$;
  }

  getWithDoing(id: string | undefined) {
    return this.fireStorageGalleryData$.pipe(
      map((gallery) => gallery.filter((gallery) => gallery.doingId == id)[0])
    );
  }

  // CREATE
  async create(gallery: GalleryObject): Promise<string> {
    let galleryId = '';
    try {
      await this.firestoreDatesCollection
        .add(gallery)
        .then((value) => (galleryId = value.id));
    } catch (err) {
      console.log(err);
    }
    return galleryId;
  }

  // UPDATE
  async update(gallery: GalleryDTO): Promise<void> {
    try {
      await this.firestoreDatesCollection.doc(gallery.id).set(
        {
          doingId: gallery.doingId,
          title: gallery.title,
          date: gallery.date,
          imageURLs: gallery.imageURLs,
        },
        { merge: false }
      );
    } catch (err) {
      console.log(err);
    }
  }

  // DELETE
  async delete(id: any): Promise<void> {
    try {
      await this.firestoreDatesCollection.doc(id).delete();
    } catch (err) {
      console.log(err);
    }
  }
}
