import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Injectable } from '@angular/core';
import {
  distinctUntilChanged,
  from,
  map,
  Observable,
  shareReplay,
  switchMap,
} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Member } from '../models/memberDTO';
import { environment } from '../../../environments/environment';
import {
  EventDTO,
  EventMonth,
  Month,
  PositionInTime,
} from '../../pages/events/eventDTO';
import { LoadingService } from './loading.service';

enum UserDefinedProperties {
  STUTZENNUMMER = 'stutzennummer',
  EINTRITTSJAHR = 'eintrittsjahr',
}

@Injectable({ providedIn: 'root' })
export class GoogleService {
  readonly googleApiEndpoint = 'https://www.googleapis.com';
  cachedEvents$: Observable<EventMonth[]> | undefined;
  cachedMembers$: Observable<Member[]>;
  cachedDriveFolder$: Observable<any>;
  cachedDriveFiles$ = {};

  constructor(
    private http: HttpClient,
    private loadingService: LoadingService,
    private afAuth: AngularFireAuth
  ) {}

  /**
   * In the environment, the main-folder-id (which must be public) is set.
   * This function looks into this main-folder and returns all child-folders (which should be doings), which then
   * can be selected and connected to a doing
   */
  get selectableDriveFolders(): Observable<any> {
    !this.cachedDriveFolder$ &&
      (this.cachedDriveFolder$ = this.performDriveQuery(
        `'${environment.googleDriveFolderId}' in parents and mimeType = 'application/vnd.google-apps.folder'`
      ));
    return this.cachedDriveFolder$;
  }

  /**
   * This returns all files in a drive folder
   */
  getFilesFromDriveFolderId(folderId: string): Observable<any> {
    !(this.cachedDriveFiles$ as any)[folderId] &&
      ((this.cachedDriveFiles$ as any)[folderId] = this.performDriveQuery(
        `'${folderId}' in parents`
      ));
    return (this.cachedDriveFiles$ as any)[folderId];
  }

  /**
   * Performs the actual query to google drive api
   * @param query
   */
  performDriveQuery(query: string): Observable<any> {
    return this.http
      .get(
        `${this.googleApiEndpoint}/drive/v3/files?q=${query}&key=${environment.gapi?.web.apiKey}`
      )
      .pipe(shareReplay(1));
  }

  get events(): Observable<EventMonth[]> {
    if (!this.cachedEvents$) {
      this.loadingService.setPageLoading(true);
      this.cachedEvents$ = this.http
        .get<EventDTO>(
          this.googleApiEndpoint +
            '/calendar/v3/calendars/kontakt@schuetzenaigen.com/events?key=' +
            environment.gapi?.web.apiKey
        )
        .pipe(
          map((eventDto) => {
            const eventMonths: EventMonth[] = [];
            eventDto.items.forEach((eventItem) => {
              const eventDateStart = new Date(
                eventItem.start.dateTime
                  ? eventItem.start.dateTime
                  : eventItem.start.date
              );
              const eventDateEnd = new Date(
                eventItem.end.dateTime
                  ? eventItem.end.dateTime
                  : eventItem.end.date
              );
              const eventMonth = eventDateStart.getMonth();
              const eventYear = eventDateStart.getFullYear();

              // If month + year does not already exist -> push to eventMonths
              !eventMonths.find(
                (eventMonthObject) =>
                  eventMonthObject.month == eventMonth &&
                  eventMonthObject.year == eventYear
              ) &&
                eventMonths.push({
                  title: Month[eventMonth] + ' ' + eventYear,
                  month: eventMonth,
                  year: eventYear,
                  events: [],
                  hasActiveEvents: false,
                });

              // Calc difference to today
              const timeDiff =
                new Date(eventDateStart).setHours(0, 0, 0, 0) -
                new Date().setHours(0, 0, 0, 0);

              // Now fill eventMonth with event
              const correspondingEventMonth: EventMonth | undefined =
                eventMonths.find(
                  (eventMonthObject) =>
                    eventMonthObject.month == eventMonth &&
                    eventMonthObject.year == eventYear
                );

              correspondingEventMonth?.events?.push({
                id: eventItem.id,
                summary: eventItem.summary,
                description: eventItem.description,
                location: eventItem.location,
                startDate: eventDateStart,
                endDate: eventDateEnd,
                calendarLink: eventItem.htmlLink,
                startTime: eventItem.start.dateTime
                  ? eventDateStart
                  : undefined,
                positionInTime:
                  timeDiff === 0
                    ? PositionInTime.TODAY
                    : timeDiff >= 0
                    ? PositionInTime.FUTURE
                    : PositionInTime.PAST,
              });

              // Now sort events inside eventMonths
              correspondingEventMonth?.events?.sort(
                (a, b) => a.startDate.getDate() - b.startDate.getDate()
              );
            });

            // Set hasActiveEvents of eventMonth
            eventMonths.map(
              (eventMonth) =>
                eventMonth.events?.find(
                  (event) => event.positionInTime !== PositionInTime.PAST
                ) && (eventMonth.hasActiveEvents = true)
            );

            // Sort by month first, then by year
            eventMonths.sort((a, b) => a.month - b.month);
            eventMonths.sort((a, b) => a.year - b.year);
            this.loadingService.setPageLoading(false);
            return eventMonths;
          })
        )
        .pipe(shareReplay(1));
    }

    return this.cachedEvents$;
  }

  get members(): Observable<Member[]> {
    if (!this.cachedMembers$) {
      this.cachedMembers$ = from(this.afAuth.authState).pipe(
        distinctUntilChanged(),
        switchMap((user) => from(<Promise<any>>user?.getIdToken())),
        switchMap((idToken) => {
          const headers = new HttpHeaders().set(
            'Authorization',
            `Bearer ${idToken}`
          );
          const url = `${environment.functionsDomain}/getGoogleContacts`;
          return this.http.get(url, { headers });
        }),
        map((contacts) => {
          const members: Member[] = [];
          (<[]>contacts).forEach((contact: any) =>
            members.push({
              id: contact.resourceName.split('/')[1],
              firstname: contact.names?.[0].givenName,
              lastname: contact.names?.[0].familyName,
              birthDate: new Date(
                contact.birthdays?.[0].date.year,
                contact.birthdays?.[0].date.month - 1,
                contact.birthdays?.[0].date.day
              ),
              street: contact.addresses?.[0].streetAddress,
              plz: contact.addresses?.[0].postalCode,
              village: contact.addresses?.[0].city,
              mail: contact.emailAddresses?.[0].value,
              phoneNumber: contact.phoneNumbers?.[0].canonicalForm,
              stutzennummer: contact.userDefined?.find(
                (value: { key: UserDefinedProperties }) =>
                  value.key === UserDefinedProperties.STUTZENNUMMER
              )?.value,
              entryYear: contact.userDefined?.find(
                (value: { key: UserDefinedProperties }) =>
                  value.key === UserDefinedProperties.EINTRITTSJAHR
              )?.value,
            })
          );
          return members
            .sort((a, b) => a.firstname.localeCompare(b.firstname))
            .sort((a, b) => a.lastname.localeCompare(b.lastname));
        }),
        shareReplay(1),
        catchError((error) => {
          console.error('Error fetching contacts:', error);
          throw error; // Re-throw the error if you want calling code to handle it.
        })
      );
    }
    return this.cachedMembers$;
  }
}
