import _ from 'lodash';
import { baseEnv, env } from './environment.base';

export const environment: env = _.merge(baseEnv, <env>{
  title: 'Prangerschützen Aigen',
  baseUrl: 'https://dev.schuetzenaigen.com',
  firebase: {
    config: {
      apiKey: 'AIzaSyAeERiuaYO4J9m_kvoFu-HDzlGGokyznHQ',
      authDomain: 'prangerschuetzenaigen.firebaseapp.com',
      projectId: 'prangerschuetzenaigen',
      storageBucket: 'prangerschuetzenaigen.appspot.com',
      messagingSenderId: '742495544993',
      appId: '1:742495544993:web:c2fa331fe7d9ce19e4b2e0',
    },
  },
  gapi: {
    web: {
      apiKey: 'AIzaSyD8CJ4g0mDxKraFqEjhgDRHBtJgiRT13vM',
      client_id:
        '742495544993-seogp76mnli6fq3ndupdkcin2dhs6302.apps.googleusercontent.com',
      project_id: 'prangerschuetzenaigen',
      auth_uri: 'https://accounts.google.com/o/oauth2/auth',
      token_uri: 'https://oauth2.googleapis.com/token',
      auth_provider_x509_cert_url: 'https://www.googleapis.com/oauth2/v1/certs',
      client_secret: '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',
      redirect_uris: [
        'https://prangerschuetzenaigen.firebaseapp.com/__/auth/handler',
      ],
      javascript_origins: [
        'http://localhost',
        'http://localhost:5000',
        'https://prangerschuetzenaigen.firebaseapp.com',
      ],
    },
  },
  functionsDomain:
    'https://europe-west3-prangerschuetzenaigen.cloudfunctions.net',
  hCaptcha: {
    siteKey: 'be2d86bc-4058-4e32-98c0-a91eabb43399',
    secret: 'ES_ac32b3f0f1084668b5aca13b57c8c100',
  },
  facebook: 'https://www.facebook.com/PrangerschuetzenAigen',
  instagram: 'https://www.instagram.com/gschossn.und.gspuet/',
  googleDriveFolderId: '1-HPfpQ4OyGgJIYbKluwEyrTIbPzm_9s_',
  translationFile: 'schuetzenaigen',
  doingTypes: {
    default: 'Standard',
    pflicht: 'Pflicht',
    vorstand: 'Vorstand',
    fahne: 'Fahne',
    begraebnis: 'Begräbnis',
  },
  seoInitialOgImage:
    'https://res.cloudinary.com/da9pctbtx/image/upload/f_auto,q_auto,w_1200/schuetzenaigen/home/ajjg01rxxvhhxyj2iz4u',
  features: {
    maintainablePages: {
      about: true,
      year: true,
      maibaum: true,
    },
  },
});
