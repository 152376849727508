import { GalleryObject } from '../models/Gallery';

export class GetGalleryData {
  static readonly type = '[Gallery] Get Data';
}

export class SetGalleryData {
  static readonly type = '[Gallery] Set Data';

  constructor(public payload: { [doingId: string]: GalleryObject }) {}
}

export class LoadAllForId {
  static readonly type = `[Gallery] Load all for id`;

  constructor(public id: string) {}
}
