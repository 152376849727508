import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  GetTranslations,
  ResetTranslations,
  SetTranslations,
  UpdateTranslation,
} from './translations.action';
import { TranslateService } from '@ngx-translate/core';
import { Translation } from '../models/Translation';
import { environment } from '../../../../../environments/environment';

interface TranslationsStateModel {
  translations: { [key: string]: Translation };
  originalTranslations?: { [key: string]: Translation };
}

@State<TranslationsStateModel>({
  name: 'translations',
  defaults: {
    translations: {},
  },
})
@Injectable()
export class TranslationsState {
  constructor(private translateService: TranslateService) {}

  @Selector()
  static translationsAsArray(state: TranslationsStateModel): Translation[] {
    let translations =
      state.translations[environment.translationFile || ''] || {};
    return Object.entries(translations)
      .filter(([key, value]) => typeof value !== 'object' && key.length > 0)
      .map(([key, value]): Translation => ({ key, value }));
  }

  @Selector()
  static hasUnsavedChanges(state: TranslationsStateModel): boolean {
    return (
      JSON.stringify(state.translations) !==
      JSON.stringify(state.originalTranslations)
    );
  }

  @Action(GetTranslations)
  getTranslations({ dispatch }: StateContext<TranslationsStateModel>) {
    dispatch(new SetTranslations(this.translateService.translations));
  }

  @Action(SetTranslations)
  setTranslations(
    { getState, setState }: StateContext<TranslationsStateModel>,
    { payload }: SetTranslations
  ) {
    const state = getState();
    // Update the original state only if it's not already set
    if (!state.originalTranslations) {
      setState({
        ...state,
        translations: payload,
        originalTranslations: payload,
      });
    } else {
      // Update only the translations
      setState({
        ...state,
        translations: payload,
      });
    }
  }

  @Action(UpdateTranslation)
  updateTranslation(
    { getState, setState }: StateContext<TranslationsStateModel>,
    { payload }: UpdateTranslation
  ) {
    const state = getState();
    const translationFile = environment.translationFile || 'default';

    // Deep copy the translations object
    let updatedTranslations = {
      ...state.translations,
      [translationFile]: {
        ...state.translations[translationFile],
        [payload.key]: payload.value,
      },
    };

    setState({
      ...state,
      translations: updatedTranslations,
    });
  }

  @Action(ResetTranslations)
  resetTranslations({
    setState,
    getState,
  }: StateContext<TranslationsStateModel>) {
    const state = getState();

    // Check if originalTranslations exists
    if (state.originalTranslations) {
      // Reset the state to the original translations while maintaining the structure of TranslationsStateModel
      setState({
        translations: { ...state.originalTranslations },
        originalTranslations: { ...state.originalTranslations },
      });
    }
  }
}
