<div class="main content-wrapper">
  <div class="width-100 footer-row-top">
    <div class="content">
      <div class="row row-first font-micro">
        <a class="font-color-light font-bold font-small" routerLink="/imprint">
          {{ 'focImprint' | translate }}</a
        >
        <a class="font-color-light pointer-none non-phone">|</a>
        <a
          class="font-color-light font-bold font-small"
          routerLink="/cookie-policy"
          >{{ 'focCookiePolicy' | translate }}</a
        >
        <a class="font-color-light pointer-none non-phone">|</a>
        <a
          class="font-color-light font-bold font-small"
          routerLink="/data-protection"
          >{{ 'focDataProtection' | translate }}</a
        >
      </div>
    </div>
  </div>
  <div class="width-100 footer-row-bottom">
    <div class="closers content">
      <span class="font-color-light font-micro font-bold"
        >Ⓒ {{ environment.title }}</span
      >
      <span class="font-color-light font-micro"
        >Entwickelt von
        <a
          class="font-micro font-color-light font-underline"
          href="mailto:tobiaszwinger.tz@gmail.com"
          >Zwinger Tobias</a
        ></span
      >
    </div>
  </div>
</div>
