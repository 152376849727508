import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { forkJoin, Observable, of, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FallbackTranslateLoader } from './fallback-translate.loader';
import { FirebaseTranslateLoader } from './firebase-translate.loader';

export function TranslateFirebaseFallbackLoaderFactory(
  http: HttpClient,
  firebaseUrl: string,
  fallbackPath: string
): TranslateLoader {
  const firebaseLoader = new FirebaseTranslateLoader(http, firebaseUrl);
  const fallbackLoader = new FallbackTranslateLoader(http, fallbackPath);

  return {
    getTranslation(lang: string): Observable<any> {
      return forkJoin({
        firebase: firebaseLoader
          .getTranslation(lang)
          .pipe(catchError(() => of({}))),
        fallback: fallbackLoader
          .getTranslation('fallbackTranslations')
          .pipe(catchError(() => of({}))),
      }).pipe(
        map((translations) => ({
          ...translations.fallback,
          ...translations.firebase,
        }))
      );
    },
  };
}
