import { Translation } from '../models/Translation';

export class GetTranslations {
  static readonly type = '[Translations] Get Data';
}

export class SetTranslations {
  static readonly type = '[Translations] Set Data';

  constructor(public payload: { [key: string]: Translation }) {}
}

export class UpdateTranslation {
  static readonly type = '[Translations] Update';

  constructor(public payload: Translation) {}
}

export class ResetTranslations {
  static readonly type = '[Translations] Reset';
}
