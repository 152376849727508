export interface EventDTO {
  items: EventDTOItem[];
}

export interface EventDTOItem {
  id: string;
  summary: string;
  description: string;
  location: string;
  start: {
    date: string;
    dateTime: string;
  };
  end: {
    date: string;
    dateTime: string;
  };

  htmlLink: string;
}

export interface EventItem {
  id: string;
  summary: string;
  description: string;
  location: string;
  startDate: Date;
  endDate: Date;
  calendarLink: string;
  startTime?: Date;
  positionInTime: PositionInTime;
}

export interface EventMonth {
  title: string;
  month: Month;
  year: number;
  hasActiveEvents: boolean; // -> today or future events
  events?: EventItem[];
}

export enum PositionInTime {
  PAST,
  TODAY,
  FUTURE,
}

export enum Month {
  Jänner,
  Februar,
  März,
  April,
  Mai,
  Juni,
  Juli,
  August,
  September,
  Oktober,
  November,
  Dezember,
}

export enum Week {
  SO,
  MO,
  DI,
  MI,
  DO,
  FR,
  SA,
}
