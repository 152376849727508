import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoadingService } from './loading.service';

const SNACKBAR_DURATION = 2000;

@Injectable({ providedIn: 'root' })
export class AlertService {
  constructor(
    private snackBar: MatSnackBar,
    private loadingService: LoadingService
  ) {}

  info(message: string) {
    this.snackBar.open(message, '', {
      duration: SNACKBAR_DURATION,
      panelClass: ['snackbar'],
    });
  }

  error(error: string) {
    console.error(error);
    this.snackBar.open(error, '', { duration: SNACKBAR_DURATION });
    this.loadingService.setPageLoading(false);
  }
}
