import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private pageLoading = new BehaviorSubject<boolean>(false);
  private pageLoadingPercentage = new BehaviorSubject<number>(-1);
  private loadingInstances: number = 0;

  public getPageLoading(): Observable<boolean> {
    return this.pageLoading.asObservable();
  }

  public getPageLoadingPercentage(): Observable<number> {
    return this.pageLoadingPercentage.asObservable();
  }

  public setPageLoading(loading: boolean) {
    this.pageLoadingPercentage.next(-1);
    loading
      ? this.loadingInstances++
      : this.loadingInstances > 0 && this.loadingInstances--;
    this.loadingInstances > 0
      ? this.pageLoading.next(true)
      : this.pageLoading.next(false);
  }

  public setPageLoadingPercentage(percentage: number) {
    this.pageLoadingPercentage.next(Math.round(percentage));
  }
}
