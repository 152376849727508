import { Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';

export const appRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./pages/home/home.component').then((m) => m.HomeComponent),
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./pages/login/login.component').then((m) => m.LoginComponent),
  },
  {
    path: 'events',
    loadComponent: () =>
      import('./pages/events/events.component').then((m) => m.EventsComponent),
  },
  {
    path: 'about',
    loadComponent: () =>
      import('./pages/about/about.component').then((m) => m.AboutComponent),
  },
  {
    path: 'year',
    loadComponent: () =>
      import('./pages/year/year.component').then((m) => m.YearComponent),
  },
  {
    path: 'contact',
    loadComponent: () =>
      import('./pages/contact/contact.component').then(
        (m) => m.ContactComponent
      ),
  },
  {
    path: 'gallery',
    loadComponent: () =>
      import('./pages/gallery/components/gallery.component').then(
        (m) => m.GalleryComponent
      ),
  },
  {
    path: 'maibaum',
    loadComponent: () =>
      import('./pages/maibaum/maibaum.component').then(
        (m) => m.MaibaumComponent
      ),
  },
  {
    path: 'data-protection',
    loadComponent: () =>
      import('./pages/data-protection/dataprotection.component').then(
        (m) => m.DataprotectionComponent
      ),
  },
  {
    path: 'cookie-policy',
    loadComponent: () =>
      import('./pages/cookie-policy/cookie-policy.component').then(
        (m) => m.CookiePolicyComponent
      ),
  },
  {
    path: 'imprint',
    loadComponent: () =>
      import('./pages/imprint/imprint.component').then(
        (m) => m.ImprintComponent
      ),
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./admin/admin.component').then((m) => m.AdminComponent),
    loadChildren: () =>
      import('./admin/admin.routes').then((m) => m.adminRoutes),
  },
  { path: '**', redirectTo: '' },
];
