import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../services/auth.service';
import { take } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(
    private router: Router,
    private authService: AuthService,
    private afAuth: AngularFireAuth
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.afAuth.authState
      .pipe(take(1))
      .subscribe((loggedId) => loggedId && this.router.navigate([state.url]));

    if (this.authService.loggedIn$.value) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}
