import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { environment } from '../environments/environment';
import { isPlatformBrowser, NgIf, registerLocaleData } from '@angular/common';
import localeAt from '@angular/common/locales/de-AT';
import {
  NavigationEnd,
  NavigationStart,
  Router,
  RouterLinkWithHref,
  RouterOutlet,
} from '@angular/router';
import { filter, tap } from 'rxjs';
import { LoadingService } from './shared/services/loading.service';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SeoService } from './shared/services/seo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    HeaderComponent,
    RouterOutlet,
    RouterLinkWithHref,
    FooterComponent,
  ],
})
export class AppComponent implements OnInit, AfterViewInit {
  destroyRef = inject(DestroyRef);
  isBrowser: boolean;
  env = environment;
  updated100Vh = 0;
  initial100Vh = 0;

  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    private router: Router,
    private loadingService: LoadingService,
    private _seoService: SeoService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
      // Necessary for local development
      window.location.hostname === 'localhost' &&
        (environment.baseUrl = 'http://localhost:4200');
    }

    this.isBrowser && window.addEventListener('resize', this.setVH.bind(this));
  }

  ngOnInit(): void {
    registerLocaleData(localeAt, 'de-AT');

    // To stop loading when rapidly switching pages
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(
          (event) =>
            event instanceof NavigationEnd &&
            this.isBrowser &&
            window.scrollTo(0, 0)
        ),
        filter((event) => event instanceof NavigationStart)
      )
      .subscribe(() => this.loadingService.setPageLoading(false));
  }

  ngAfterViewInit(): void {
    if (this.isBrowser) {
      this.setVH(); // Add this line to set the height after view initialization
    }
  }

  // Add this method to directly set the height of the '.below-header' element
  setVH(): void {
    // This sets the initial 100vh found
    if (this.initial100Vh === 0) {
      this.initial100Vh = window.innerHeight;
      document.documentElement.style.setProperty(
        '--initial-100-vh-found',
        `${this.initial100Vh}px`
      );
    }

    this.updated100Vh = window.innerHeight;
    document.documentElement.style.setProperty(
      '--real-100-vh',
      `${this.updated100Vh}px`
    );
  }
}
