import { provideRouter } from '@angular/router';
import {
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import {
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { appRoutes } from './app.routes';
import {
  BrowserModule,
  provideClientHydration,
  TransferState,
} from '@angular/platform-browser';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../environments/environment';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  provideTippyConfig,
  TippyDirective,
  tooltipVariation,
} from '@ngneat/helipopper';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatSelectModule } from '@angular/material/select';
import { NgOptimizedImage, provideCloudinaryLoader } from '@angular/common';
import { NgxImageCompressService } from 'ngx-image-compress';
import { provideAnimations } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { PagesState } from './admin/maintain/content-maintain/store/pages.state';
import { NgHcaptchaModule } from 'ng-hcaptcha';
import { provideServiceWorker } from '@angular/service-worker';
import { GalleryState } from './pages/gallery/store/gallery.state';
import { TranslateFirebaseFallbackLoaderFactory } from './shared/loaders/translate-loader.factory';
import { TranslationsState } from './admin/maintain/translations-maintain/store/translations.state';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      NgxsModule.forRoot([PagesState, GalleryState, TranslationsState], {
        developmentMode: !environment.production,
      }),
      NgHcaptchaModule.forRoot({
        siteKey: environment.hCaptcha?.siteKey,
      }),
      NgxsReduxDevtoolsPluginModule.forRoot(),
      BrowserModule,
      AngularFireModule.initializeApp(
        environment.firebase ? environment.firebase.config : {}
      ),
      AngularSvgIconModule.forRoot(),
      MatInputModule,
      ReactiveFormsModule,
      MatButtonModule,
      MatSnackBarModule,
      MatDialogModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MatToolbarModule,
      MatIconModule,
      MatProgressBarModule,
      MatExpansionModule,
      MatTooltipModule,
      TippyDirective,
      MatTableModule,
      MatSortModule,
      FormsModule,
      MatStepperModule,
      MatCheckboxModule,
      DragDropModule,
      MatSlideToggleModule,
      GoogleMapsModule,
      MatSelectModule,
      NgOptimizedImage,
      TranslateModule.forRoot({
        defaultLanguage: environment.translationFile,
        loader: {
          provide: TranslateLoader,
          useFactory: (http: HttpClient, transferState: TransferState) =>
            TranslateFirebaseFallbackLoaderFactory(
              http,
              `${environment.firebase?.storageDomain}${environment.firebase?.config.storageBucket}/o/translations%2F`,
              '/assets/i18n'
            ),
          deps: [HttpClient, TransferState],
        },
      })
    ),
    provideCloudinaryLoader('https://res.cloudinary.com/da9pctbtx'),
    provideClientHydration(),
    provideRouter(appRoutes),
    NgxImageCompressService,
    provideTippyConfig({
      defaultVariation: 'tooltip',
      variations: { tooltip: tooltipVariation },
    }),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
};
