import { Action, State, StateContext, StateToken } from '@ngxs/store';
import { PageE } from '../../../../shared/models/Page';
import { Injectable } from '@angular/core';
import { Page, Pages } from './page.action';
import { Content } from '../../../../shared/models/Content';
import Add = Pages.Add;
import AddContent = Page.AddContent;

export interface Pages {
  [pageName: string]: PageModel;
}

export interface PageModel {
  [content: string]: Content;
}

const PAGE_STATE_TOKEN = new StateToken<PageE>('page');

@State<Pages>({ name: PAGE_STATE_TOKEN })
@Injectable()
export class PagesState {
  @Action(Add) // Add a page to the store
  addPage(ctx: StateContext<Pages>, action: Add) {
    ctx.patchState({ ...ctx.getState(), [action.page]: {} });
  }

  @Action(AddContent) // Add a content to page
  addPageContent(ctx: StateContext<Pages>, action: AddContent) {
    const state = ctx.getState();
    const pageName = action.page;
    if (state[pageName]) {
      const existingContents = state[pageName][action.content.id];
      const newContents = {
        ...existingContents,
        ...action.content.parts,
      };
      const updatedPage = {
        ...state[pageName],
        [action.content.id]: newContents,
      };
      ctx.patchState({ ...state, [pageName]: updatedPage });
    }
  }

  // @Action(RemoveContent) // Add a content to page
  // removePageContent(ctx: StateContext<Pages>, action: RemoveContent) {
  //   const state = ctx.getState() as unknown as Pages;
  //   const pageName = action.page;
  //   if (state[pageName]) {
  //     const existingContents = state[pageName][action.contentId];
  //     const newContents = {...existingContents};
  //     delete newContents[action.contentId];
  //
  //     const updatedPage = {
  //       ...state[pageName],
  //       contents: newContents,
  //     };
  //
  //     ctx.patchState({...state, [pageName]: updatedPage});
  //   }
  // }
}
