import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { UserDTO } from '../models/userDTO';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { LoadingService } from './loading.service';
import { AlertService } from './alert.service';
import { LocalstorageService } from './localstorage.service';

// Source: https://www.positronx.io/full-angular-firebase-authentication-system/
@Injectable({ providedIn: 'root' })
export class AuthService {
  userData: any; // Save logged in user data
  loggedIn$ = new BehaviorSubject<boolean>(false);
  superUser$ = new BehaviorSubject<boolean>(false);

  constructor(
    private loadingService: LoadingService,
    private afs: AngularFirestore, // Inject Firestore service
    private afAuth: AngularFireAuth, // Inject Firebase auth service
    private alertService: AlertService,
    private router: Router,
    private localstorageService: LocalstorageService
  ) {
    this.afAuth.authState.subscribe((user) => user && this.login(user));
  }

  isLoggedIn$(): Observable<boolean> {
    return this.loggedIn$.asObservable();
  }

  get isSuperUser$(): Observable<boolean> {
    return this.superUser$.asObservable();
  }

  get isSuperUser(): boolean {
    return this.superUser$.value;
  }

  // Sign in with email/password
  signIn(email: string, password: string) {
    this.loadingService.setPageLoading(true);
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.login(result.user);
        this.afAuth.authState.subscribe((user) => {
          this.loadingService.setPageLoading(false);
          if (user) {
            this.alertService.info('Erfolgreich angemeldet');
            this.router.navigate(['/admin/doings']);
          } else {
            this.alertService.info('Erfolgreich abgemeldet');
          }
        });
      })
      .catch(() => this.loadingService.setPageLoading(false));
  }

  // Sign up with email/password
  // signUp(email: string, password: string) {
  //   return this.afAuth
  //     .createUserWithEmailAndPassword(email, password)
  //     .then((result) => {
  //       /* Call the SendVerificaitonMail() function when new user sign
  //       up and returns promise */
  //       // set role
  //       this.sendVerificationMail();
  //       this.setUserData(result.user);
  //     })
  //     .catch(() => {
  //       // window.alert(error.message);
  //     });
  // }

  // Send email verfificaiton when new user sign up
  sendVerificationMail() {
    return this.afAuth.currentUser
      .then((u: any) => u.sendEmailVerification())
      .then(() => {
        this.router.navigate(['verify-email-address']);
      });
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail: string) {
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        // window.alert('Password reset email sent, check your inbox.');
      })
      .catch(() => {
        // window.alert(error);
      });
  }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  login(user: any) {
    const userRef: AngularFirestoreDocument<UserDTO> = this.afs.doc(
      `users/${user.uid}`
    );

    userRef
      .valueChanges()
      .pipe(take(1))
      .subscribe((userData: UserDTO | undefined) => {
        if (userData) {
          this.loggedIn$.next(true);
          const fullUserData = {
            ...userData,
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
            emailVerified: user.emailVerified,
          };
          fullUserData.role === 'super' && this.superUser$.next(true);
          this.userData = fullUserData;
          this.localstorageService.setItem(
            'user',
            JSON.stringify(fullUserData)
          );
        } else {
          this.loggedIn$.next(false);
        }
      });
  }

  // Sign out
  signOut() {
    this.loadingService.setPageLoading(true);
    return this.afAuth.signOut().then(() => {
      this.loggedIn$.next(false);
      this.superUser$.next(false);
      this.localstorageService.removeItem('user');
      this.router.navigate(['login']);
      this.loadingService.setPageLoading(false);
    });
  }
}
