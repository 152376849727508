import { PageE } from '../../../../shared/models/Page';
import { Content } from '../../../../shared/models/Content';

export namespace Pages {
  export class Add {
    static readonly type = '[Content Maintain] Add Page';

    constructor(public page: PageE) {}
  }
}

export namespace Page {
  export class AddContent {
    static readonly type = '[Content Maintain] Add Page Content';

    constructor(
      public page: PageE,
      public content: Content
    ) {}
  }

  export class RemoveContent {
    static readonly type = '[Content Maintain] Remove Page Content';

    constructor(
      public page: PageE,
      public contentId: string
    ) {}
  }
}
