import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GalleryObject } from '../models/Gallery';
import { GalleryService } from '../services/gallery.service';
import { GetGalleryData, LoadAllForId, SetGalleryData } from './gallery.action';

interface GalleryStateModel {
  galleries: { [doingId: string]: GalleryObject };
}

@State<GalleryStateModel>({
  name: 'gallery',
  defaults: {
    galleries: {},
  },
})
@Injectable()
export class GalleryState {
  constructor(private galleryService: GalleryService) {}

  // The selector now returns a map of GalleryObjects indexed by doingId
  @Selector()
  static galleriesAsArray(state: GalleryStateModel): GalleryObject[] {
    return Object.values(state.galleries);
  }

  // This action is responsible for fetching the gallery data and dispatching SetGalleryData
  @Action(GetGalleryData)
  getGalleryData({ dispatch }: StateContext<GalleryStateModel>) {
    return this.galleryService.galleryData.subscribe((data) => {
      // Specify the type of the accumulator in the reduce function to avoid TS7053 error
      const galleriesMap = data.reduce<{ [key: string]: GalleryObject }>(
        (map, obj) => {
          if (obj.doingId) {
            map[obj.doingId] = obj;
          }
          return map;
        },
        {}
      );

      dispatch(new SetGalleryData(galleriesMap));
    });
  }

  // This action is responsible for setting the gallery data in the state
  @Action(SetGalleryData)
  setGalleryData(
    { setState }: StateContext<GalleryStateModel>,
    { payload }: SetGalleryData
  ) {
    setState({ galleries: payload });
  }

  @Action(LoadAllForId)
  loadAllForId(
    { getState, patchState }: StateContext<GalleryStateModel>,
    { id }: LoadAllForId
  ) {
    const state = getState();
    // Check if the gallery entry with the given ID exists
    if (state.galleries[id]) {
      // Use patchState to create a new object for the galleries entry
      patchState({
        galleries: {
          ...state.galleries,
          [id]: {
            ...state.galleries[id],
            loadAll: true, // Set the loadVideos property to true
          },
        },
      });
    }
  }
}
