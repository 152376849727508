import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, filter, take } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  private lastMainRoute: string;

  constructor(
    private metaService: Meta,
    private translate: TranslateService,
    private titleService: Title,
    private router: Router
  ) {
    const translationLoaded$ = this.translate
      .use(environment.translationFile || '')
      .pipe(take(1));

    const routerEvent$ = this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    );

    // Whenever the main route changes, we try to set the seo data corresponding to this route set in the translations
    combineLatest([translationLoaded$, routerEvent$]).subscribe(
      ([translation, event]) => {
        // 'event' is the NavigationEnd event from routerEvent$
        let mainRoute = event.urlAfterRedirects.split('/')[1]; // This gets the first segment after the initial slash

        if (mainRoute !== this.lastMainRoute) {
          mainRoute === '' && (mainRoute = 'home');
          this.lastMainRoute = mainRoute;
          this.resetInitialSeoTags(mainRoute);
          this.updateSeoTagsByTranslationFileAndPage(
            this.toPascalCase(mainRoute)
          );
        }
      }
    );
  }

  private resetInitialSeoTags(rootRoute: string) {
    this.titleService.setTitle(this.translate.instant('seoInitialTitle'));
    this.metaService.updateTag({
      name: 'description',
      content: this.translate.instant('seoInitialDescription'),
    });
    this.metaService.updateTag({
      property: 'og:description',
      content: this.translate.instant('seoInitialDescription'),
    });
    this.metaService.updateTag({
      property: 'og:title',
      content: this.translate.instant(
        this.translate.instant('seoInitialTitle')
      ),
    });
    this.metaService.updateTag({
      property: 'og:image',
      content: environment.seoInitialOgImage || '',
    });
    this.metaService.updateTag({
      name: 'robots',
      content: 'noindex, nofollow',
    });
    environment.nonIndexedRoutes?.includes(rootRoute) || // -> set robots index only if route is not excluded
      this.metaService.updateTag({
        name: 'robots',
        content: 'index, follow',
      });
    this.metaService.updateTag({
      property: 'og:url',
      content: `${environment.baseUrl}/${rootRoute}`,
    });
  }

  /**
   * This will set title, og:title, description and og:description if translated
   * @param page
   * @private
   */
  private updateSeoTagsByTranslationFileAndPage(page: string) {
    const descriptionTranslationKey = `seo${page}Description`;
    const descriptionTranslation = this.translate.instant(
      descriptionTranslationKey
    );

    if (descriptionTranslation !== descriptionTranslationKey) {
      this.metaService.updateTag({
        name: 'description',
        content: descriptionTranslation,
      });
      this.metaService.updateTag({
        property: 'og:description',
        content: descriptionTranslation,
      });
    }

    const titleTranslationKey = `seo${page}Title`;
    const titleTranslation = this.translate.instant(titleTranslationKey);

    if (titleTranslation !== titleTranslationKey) {
      this.metaService.updateTag({
        property: 'og:title',
        content: titleTranslation,
      });

      this.titleService.setTitle(titleTranslation);
    }
  }

  setOgImage(src: string) {
    this.metaService.updateTag({
      property: 'og:image',
      content: src,
    });
  }

  /**
   * To convert the route (cookie-policy) to the translation-key (CookiePolicy)
   * @param str
   * @private
   */
  private toPascalCase(str: string): string {
    return str
      .split('-')
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
      .join('');
  }
}
