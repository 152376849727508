import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { distinctUntilChanged, map, Observable, shareReplay } from 'rxjs';
import { Doing, DoingDTO } from '../../shared/models/doingDTO';

@Injectable({ providedIn: 'root' })
export class DoingsService {
  constructor(private firestore: AngularFirestore) {}

  firestoreDoingsRef = this.firestore.collection('doings');
  driveFoldersRef = this.firestore.collection('doings', (ref) =>
    ref.where('driveFolder', '!=', '')
  );

  cachedDoings$: Observable<Doing[]>;
  cachedDoingsWithDriveFolders$: Observable<Doing[]>;

  get doings(): Observable<Doing[]> {
    if (!this.cachedDoings$) {
      this.cachedDoings$ = <Observable<Doing[]>>(
        this.firestoreDoingsRef.snapshotChanges().pipe(
          distinctUntilChanged(),
          map((doings) => doings.map(this.mapDoing)),
          shareReplay(1)
        )
      );
    }
    return this.cachedDoings$;
  }

  get(id: string): Observable<Doing> {
    return this.doings.pipe(
      map((doings) => doings.filter((doing) => doing.id == id)[0])
    );
  }

  /**
   * This will return the driveFolder from doings with a Google Drive folder connected - so with images for the gallery
   */
  get doingsWithDriveFolders(): Observable<Doing[]> {
    if (!this.cachedDoingsWithDriveFolders$) {
      this.cachedDoingsWithDriveFolders$ = <Observable<Doing[]>>(
        this.driveFoldersRef.snapshotChanges().pipe(
          distinctUntilChanged(),
          map((doings) => doings.map(this.mapDoing)),
          shareReplay(1)
        )
      );
    }
    return this.cachedDoingsWithDriveFolders$;
  }

  // CREATE
  async create(doing: Doing): Promise<string> {
    let doingId = '';
    try {
      await this.firestoreDoingsRef
        .add(doing)
        .then((value) => (doingId = value.id));
    } catch (err) {
      console.log(err);
    }
    return doingId;
  }

  // UPDATE
  async update(doing: Doing): Promise<void> {
    // Delete fields if they are undefined
    Object.keys(doing).forEach(
      (key) => (doing as any)[key] === undefined && delete (doing as any)[key]
    );
    try {
      await this.firestoreDoingsRef.doc(doing.id).set(doing, { merge: false });
    } catch (err) {
      console.log(err);
    }
  }

  // DELETE
  async delete(id: any): Promise<void> {
    try {
      await this.firestoreDoingsRef.doc(id).delete();
    } catch (err) {
      console.log(err);
    }
  }

  mapDoing(doing: any) {
    const mappedDoing = doing.payload.doc.data() as DoingDTO;
    mappedDoing.id = doing.payload.doc.id;
    mappedDoing.date = mappedDoing.date?.toDate();
    mappedDoing.dateTo = mappedDoing.dateTo?.toDate();
    return mappedDoing;
  }
}
