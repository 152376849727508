export type env = Record<keyof typeof baseEnv, any>;

export const baseEnv = {
  title: '',
  production: false,
  live: false,
  baseUrl: 'http://localhost:4200',
  firebase: {
    config: {
      apiKey: '',
      authDomain: '',
      projectId: '',
      storageBucket: '',
      messagingSenderId: '',
      appId: '',
    },
    storageDomain: 'https://firebasestorage.googleapis.com/v0/b/',
  },
  gapi: {
    web: { client_id: '', apiKey: '' },
  },
  translationFile: '',
  doingTypes: {},
  functionsDomain: '',
  hCaptcha: {
    siteKey: '',
    secret: '',
  },
  facebook: '',
  instagram: '',
  googleDriveFolderId: '', // -> This folderId should point to a folder which is public in the internet and contains folders which can then be chosen for a doing
  seoInitialOgImage: '',
  nonIndexedRoutes: 'admin, login',
  features: {
    highlightImage: false,
    imagesViaDrive: false, // -> Only one should be true
    imagesViaFirebaseStorage: true, // -> Only one should be true
    maintainablePages: {
      // Keys here must match the page-keys in {app}.json
      home: false,
      about: false,
      contact: false,
      events: false,
      gallery: false,
      admin: false,
      data_protection: false,
      imprint: false,
      year: false,
      maibaum: false,
    },
  },
};
